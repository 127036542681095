<template>
  <div class="plaka_logout">
    Logout...
  </div>
</template>

<script>
import { Store, CMS, USER } from '@/store'

export default {
  name: 'cmslogout',
  methods: {
    onInitComponent () {
      this.logout()
    },
    logout () {
      Store.commit(CMS.MUTATIONS.REMOVE_USER)
      Store.commit(USER.MUTATIONS.REMOVE_ALL_USERS)
      Store.dispatch({ type: USER.ACTIONS.LOGOUT, router: this.$router })
    }
  }
}
</script>
<style scoped lang="scss">
</style>
